import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Image,
  Text,
  Heading,
  Container,
  SimpleGrid,
  Button,
  VStack,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import simplified from "./assets/tree.svg";
import chart_up from "./assets/market.svg";
import trophy from "./assets/assets.svg";
import globe from "./assets/lock.svg";
import {
  RakeoffPurpleHue,
  boxBackgroundColor,
  boxBorderColor,
  boxFontColor,
  mainBackgroundColor,
} from "../colors";

const RakeoffStakingFeatures = () => {
  return (
    <Container maxW="7xl" mt={{ base: 6, md: "5rem" }} p={0}>
      <SimpleGrid
        columns={[1, 1, 2]}
        mx={{ base: 3, md: 3, lg: 0 }}
        bg={`linear-gradient(to top, ${boxBackgroundColor} 30%, ${mainBackgroundColor} 100%)`}
        borderBottom={boxBorderColor}
        borderRadius="3xl"
        py={12}
        px={8}
      >
        <Flex justify="center" align="start">
          <VStack align="start" mb={8} gap={8}>
            <Heading color="black" size={{ base: "2xl", md: "xl" }} maxW="md">
              Degen Protocol Logic
            </Heading>

            <Button
              rightIcon={<ArrowForwardIcon />}
              as="a"
              href="#"
              target="_blank"
              bg={boxBackgroundColor}
              _hover={{
                boxShadow: `0px 0px 10px 6px ${RakeoffPurpleHue}`,
              }}
              boxShadow={`0px 0px 10px 3px ${RakeoffPurpleHue}`}
              color="white"
              size="lg"
            >
              Launch dApp
            </Button>
          </VStack>
        </Flex>
        <Accordion allowMultiple width="100%" rounded="lg">
          <StakingFeature
            image={simplified}
            heading={"Minting NFTs"}
            description={
              "Craft your own digital masterpieces as NFTs, each with a unique story and value."
            }
            isTop
          />
          <StakingFeature
            image={trophy}
            heading={"Locking Tokens"}
            description={
              "Securely lock ERC-20 tokens within your NFTs, adding intrinsic value and uniqueness to your collectibles."
            }
          />
          <StakingFeature
            image={globe}
            heading={"Unlocking Tokens"}
            description={
              "When the time is right, unlock and access your locked tokens, turning your NFTs into a vault of opportunities."
            }
          />
          <StakingFeature
            image={chart_up}
            heading={"Trade NFTs and take collateral"}
            description={
              "Trade your NFTs and even use them as collateral for financial ventures. Your NFTs, your financial power."
            }
            isBottom
          />
        </Accordion>
      </SimpleGrid>
    </Container>
  );
};
export default RakeoffStakingFeatures;

const StakingFeature = ({ image, heading, description, isTop, isBottom }) => {
  return (
    <AccordionItem
      borderTop={isTop ? "none" : "auto"}
      borderBottom={isBottom ? "none" : "auto"}
    >
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        mx={0}
        px={0}
        py={6}
      >
        <Flex
          p={3}
          bgGradient="linear(to-br, #6528c8, #a25fc2)"
          borderRadius="lg"
          h={"80px"}
          w={"85px"}
          align="center"
          justify="center"
        >
          <Image src={image} alt={heading} h={"100%"} w={"100%"} />
        </Flex>
        <Heading size={"lg"} color="white">
          {heading}
        </Heading>
        <AccordionIcon color="white" />
      </AccordionButton>
      <AccordionPanel pb={6} pt={0} px={0}>
        <Text color={boxFontColor}>{description}</Text>
      </AccordionPanel>
    </AccordionItem>
  );
};
