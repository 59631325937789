import React from "react";
import { Box } from "@chakra-ui/react";
import Banner from "./sections/Banner";
import Footer from "./sections/Footer";
import ThreeSteps from "./sections/ThreeSteps";
import Staking from "./sections/Staking";

const App = () => {
  return (
    <Box>
      <Banner />
      <Staking/>
      <ThreeSteps/>
      <Footer />
     
    </Box>
  );
};

export default App;

